<template>
  <div
    id="security-page"
  >
    <b-container
      fluid
    >
      <b-row class="vh-100">
        <b-col
          cols="7"
          class="d-none d-lg-flex flex-column py-4"
        >
          <div>
            <b-link class="brand-logo">
              <foravila-logo />
            </b-link>
          </div>
          <div class="my-auto text-center illustration-wrapper">
            <slot name="left" />
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="5"
          class="bg-white d-md-flex flex-column py-4"
        >
          <div class="d-flex d-lg-block justify-content-between">
            <b-link class="brand-logo-mobile d-block d-lg-none">
              <foravila-logo />
            </b-link>
            <b-navbar-nav class="text-right mr-3">
              <locale class="locale-selector" />
            </b-navbar-nav>
          </div>
          <div class="m-auto px-lg-6 px-2 w-100 login-form-wrapper">
            <slot
              name="right"
            />
          </div>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BRow, BCol, BContainer,
  BLink,
  BNavbarNav,
} from 'bootstrap-vue'
import { localize } from 'vee-validate'
import ForavilaLogo from '@/layouts/foravila/components/ForavilaLogo.vue'
import Locale from '@/layouts/foravila/components/app-navbar/components/Locale.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BContainer,
    BNavbarNav,
    ForavilaLogo,
    Locale,
  },
  mounted() {
    localize(this.$i18n.locale.substring(0, 2).toLowerCase())
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
  #security-page {
    .login-form-wrapper {
      max-width: 600px;
    }
    .login-form-wrapper > div {
      margin-top: 3rem;
      @include media-breakpoint-up(md) {
        margin-top: -30px;
      }
    }
    .illustration-wrapper > div {
      margin-top: -30px;
    }
    .locale-selector {
      a {
        color: #6e6b7b;
      }
    }
  }

</style>
