<template>
  <div class="illustration">
    <b-img
      fluid
      :src="src"
      alt="Page illustration"
    />
  </div>

</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: { BImg },
  props: {
    src: {
      type: String,
      // eslint-disable-next-line global-require
      default: require('@/assets/images/pages/forgot-password-v2.svg'),
    },
  },
}
</script>
<style lang="scss">
  .illustration {
    img {
      width: 600px;
    }
  }
</style>
